@import "../../../../../../widget-library/postlisting/sass/widget-layout-41.scss";
@import "../../_variables";
// @import "../../_mixins";

.mod-listing.widget-layout-41.mod-home-news {
	&:before{
		
	}
	.article-title {
		overflow: hidden;

		.title {

			a,
			span {
				display: block;
				height: 100%;
				position: relative;
			}
		}
	}
	.item-type-icon{
		left: 10px;
	}

	.article-item {
		.article-wrap {
			// margin-bottom: 15px;
		}
	}
	.first{
		.article-title .title {
			font-size: 24px;
			max-height: 100px;
			font-family: $bold_font-family;
			margin: 0;   
			// line-height: 1.3;
			// padding-right: 30px;
			color: $primary-color;
			overflow: hidden;
		}
		.article-description {
			overflow: hidden;
			// max-height: 110px;
			max-height: 100px;
			margin-bottom: 3px;
			p{
				color: $primary-color;
			}
		}
	}
	.second{
		.article-title .title {
			max-height: 75px;
			font-family: $bold_font-family;
			margin: 0;
			// line-height: 1.4;
			color: $primary-color;
			font-size: 18px;
			letter-spacing: 1px;
			padding-right: 30px;
			letter-spacing: 0;
			max-height: 48px;
			overflow: hidden;
		}
		.article-description{
			overflow: hidden;
			max-height: 42px;
		}
	}
	/* .article-content:hover {
		.img-box{
			&:after{
				display: none;
			}
		}
	} */
}


@media screen and (max-width:$media-desktop-max) {
	.mod-listing.widget-layout-41.mod-home-news .first .article-title .title {
		font-size: 20px;
		max-height: 50px;
		line-height: 1.2;
		padding-right: 15px;
	}


}


/*Max width 1024px Ends*/


/*Max width 992px Starts*/

@media screen and (max-width:$media-tablet-max) {
	.mod-listing.widget-layout-41.mod-home-news {
		.first {
			.article-title .title {
				font-size: 16px;
				max-height: 41px;
				line-height: 1.2;
			}
			.article-description {
				max-height: 53px;
				p {
					font-size: 14px;
				}
			}
		}
		.second {
			.article-title .title{
				font-size: 16px;
				max-height: 41px;
				// line-height: 1.2;
			}
			.article-description{
				max-height: 39px;
				p{
					font-size: 14px;
				}
			}
		}
	}

	
}


/*Max width 992px Ends*/


/*Max width 767px Starts*/

@media screen and (max-width:$media-phone-max) {

	.mod-listing.widget-layout-41.mod-home-news .article-content{
		margin-top: 10px;
	}

}


/*Max width 767px ends*/