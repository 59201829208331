// Colors scheme
$default-color: #000000;
$primary-color: #0d2a4a; //dark-blue
$secondary-color: #e81e25; //red
$third-color: #113c66; //light-blue
$complementary-color:#ffffff;
// $link-color: #FD0106;
// Fonts
$regular_font-family:"RobotoSlabRegular",
sans-serif;
$bold_font-family:"RobotoSlabBold",
sans-serif;
$light_font-family:"RobotoSlabLight",
sans-serif;
$thin_font-family:"RobotoSlabThin",
sans-serif;


$googlePlus:#dd4b39;
$facebook:#3b5998;
$twitter:#1da1f2;
$whatsApp:#7ad06d;
$instagram:#125688;
$success:#a0c931;
$empty:#27c2b3;
$unsuccess:#ff593b;
$superTackle:#b45ef6;
$livMat:red;
$completedMat:green;
$upcomingMat:blue;
$success:#7eb81e;


/*_Roboto Fonts_*/

$regular_secondary_font-family:'Roboto-Regular',
sans-serif;
$light_secondary_font-family:'Roboto-Thin',
sans-serif;
$medium_secondary_font-family:'Roboto-Medium',
sans-serif;
$bold_secondary_font-family:'Roboto-Bold',
sans-serif;
$black_secondary_font-family:'Roboto-Black',
sans-serif;

