@import "../../../sass/_variables";
@import "../../../sass/_mixins";

.mod-listing.widget-layout-41
{
	.mod-head 
	{
		.title 
		{
			margin: 0;
		}

	}

	.article-item
	{
		position: relative;
		margin-bottom: 10px;

	}

}


@media screen and (max-width:$media-desktop-max) {

}


/*Max width 1024px Ends*/


/*Max width 992px Starts*/

@media screen and (max-width:$media-tablet-max) {}


/*Max width 992px Ends*/


/*Max width 767px Starts*/

@media screen and (max-width:$media-phone-max) {

	.mod-listing.widget-layout-41
	{
		.article-item
		{
			&.col-1 
			{
				.article-description
				{
					display: none;
				}
			}
		}

	}

}


/*Max width 767px ends*/